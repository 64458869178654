import React, { useMemo } from 'react';
import { ProductFruits } from 'react-product-fruits';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

import { useAuth } from '../../containers/AuthContext/AuthContext';
import { PRODUCT_FRUIT_CODE } from '../../utils/constants';

export function OnboardTool() {
  const history = useHistory();
  const { user, subscription } = useAuth();

  const onboardIdentification = useMemo(() => ({
    username: user.email,
    email: user.email,
    firstname: user.firstName,
    lastname: user.lastName,
    role: user?.isOwner ? 'owner' : 'not_owner',
    props: {
      planName: user?.organization?.planName,
      state: user?.organization.state,
      employeesNumber: user?.organization.employeesNumber,
      documentsSentInMonth: user?.organization.documentsSentInMonth,
      operationSegment: user?.organization.operationSegment,
      paymentAttempts: subscription?.paymentAttempts,
      insertedAt: user.insertedAt,
      planCycle: subscription?.cycle
    }
  }), [user, subscription]);

  return (
    <ProductFruits
      lifeCycle="neverUnmount"
      workspaceCode={PRODUCT_FRUIT_CODE}
      language="pt"
      user={onboardIdentification}
      config={{
        customNavigation: {
          use: true,
          navigate: (url) => history.push(url),
          onGet() {
            return window.location.href;
          }
        }
      }}
    />
  );
}
